* {
  box-sizing: border-box;
}

body {
  background-image: url("whitenoise.png");
}

.pic {
  float: left;
  margin-right: 20px;
}

.row {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.bio {
  float: left;
  font-size: 17px;
}
div {
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
.home {
  margin-top: 10px;
}
.home img {
  width: 150px;
  border-radius: 150px;
}
.main {
  padding-left: 5px;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: midnightblue;
}

h2 {
  margin-top: 0;
}

a {
  color: royalblue;
}

li {
  margin-top: 4px;
}

.cv {
  padding: 0;
  margin-top: 7px;
}

.teaching,
.thesis {
  font-size: 17px;
  font-weight: bold;
  margin-top: 6px;
}

.thesis-items {
  font-size: 16px;
  font-weight: normal;
}

section {
  margin-top: 30px;
}

.biolink a {
  margin-right: 10px;
}
